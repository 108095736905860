@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "regular";
  src: url("./assets/fonts/MonaSansExpanded-Regular.ttf");
}

@font-face {
  font-family: "light";
  src: url("./assets/fonts/MonaSansExpanded-Light.ttf");
}

@font-face {
  font-family: "semibold";
  src: url("./assets/fonts/MonaSansExpanded-SemiBold.ttf");
}

@font-face {
  font-family: "bold";
  src: url("./assets/fonts/MonaSansExpanded-Bold.ttf");
}

@font-face {
  font-family: "extra-bold";
  src: url("./assets/fonts/MonaSansExpanded-ExtraBold.ttf");
}

@font-face {
  font-family: "black";
  src: url("./assets/fonts/MonaSansExpanded-Black.ttf");
}

@font-face {
  font-family: "futumdbt";
  src: url("./assets/fonts/FutuMd.ttf");
}

.futumd {
  font-family: "futumdbt";
}

* {
  font-family: "regular";
}

.light {
  font-family: "light";
}

.bold {
  font-family: "bold";
}

.medium {
  font-family: "semibold";
}