body,
html {
    overflow-x: hidden;
    background: #111111;
}

.container {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 15px;
}

main {
    background: url("../images/bg.png");
    background-size: 100%;
    background-position: top;
    background-repeat: no-repeat;
}

h1 {
    line-height: 80px;
    font-weight: 500;
}

.ant-picker * {
    color: white !important;
    font-size: 20px !important;
}

.ant-picker input::placeholder {
    color: white !important;
    font-size: 20px !important;
}

@media screen and (max-width: 992px) {
    h1 {
        line-height: 50px;
        font-weight: 500;
    }
}

@media screen and (max-width: 767px) {
    h1 {
        line-height: 35px;
        font-weight: 500;
    }
}

.card {
    box-shadow: 0px 7px 48px 0px rgba(0, 0, 0, 0.10);
}

.ck {
    background: #111 !important;
    border: unset !important;
    color: white !important;
}

.ck.ck-editor__top.ck-reset_all {
    border-bottom: 1px solid white !important;
    padding-bottom: 10px;
}

.lh-40 {
    line-height: 50px;
}

input,
select {
    font-family: light;
}
